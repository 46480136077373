import React from 'react';
import '../styles/app.css';

import Global from "../styles/global";

import Header from "../components/header"
import Product from "../components/product"
import Footer from "../components/footer"
import { Helmet } from 'react-helmet'
import ReactPlayer from "react-player/youtube";
const backgroundWall = require("../images/wallpaperflare.com_wallpaper.jpg");

const divStyle = {
  
  backgroundImage: `url(${backgroundWall})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'fixed'
};


class Home extends React.Component {
  state = {
    lang : this.props.lang ? (this.props.lang.includes('fr') ? 'fr' : 'en') : ((navigator.language || navigator.userLanguage).includes('fr') ? 'fr' : 'en')
  };

  render(){

    let actLang = this.props.lang ? (this.props.lang.includes('fr') ? 'fr' : 'en') : this.state.lang

    const Pub = require("../images/under_construction_"+ actLang +".png");

    const config = {
      height:"100%",
      objectFit:"cover",
      youtube: {
          playerVars: {
              disablekb: 1,
              modestbranding: 1,
              showinfo: 0,
              iv_load_policy: 3,
              origin: 0,
              disablePictureInPicture: true,
              rel: 1,
          }
      },
      file: {
          forceVideo: true,
          attributes: {
            preload: 'metadata',
          },
        },
  }
    return (
      <div className='App' style={divStyle}>
        <Helmet>
              <html lang={actLang} />
              <title>{ actLang === 'fr' ? 'V-Forge Grill et accessoires portatif en acier inxydable' : 'V-Forge Stainless steel portable grill and accessories'}</title>
              <meta name="Description" content={ actLang === 'fr' ? "V-Forge, Grill, four à pizza, panier à grillade, panier multi-usage, portatif et fabriqué en acier inoxydable de grade alimentaire." : "V-Forge, Grill, Pizza Oven, Grill Basket, Multi-Use Basket, Portable and Made of Food Grade Stainless Steel."} />
              <meta name="Keywords" content={ actLang === 'fr' ? "grill, barbecue, bbq, plein air, grill portatif, bbq portatif, grillage, accessoire grill, accessoire bbq, accessoire barbecue, four pizza, four à pizza, four pizza portatif" : "grill, barbecue, bbq, outdoors, portable grill, portable bbq, grilling, grill accessory, bbq accessory, barbecue accessory, pizza oven, pizza oven, portable pizza oven"} />

              <link rel="schema.DC" href="http://purl.org/dc/elements/1.1/" />
              <meta name="DC.title" content={ actLang === 'fr' ? 'V-Forge Grill et accessoires portatif en acier inxydable' : 'V-Forge Stainless steel grill and accessories'} />
              <meta name="DC.Description" content={ actLang === 'fr' ? "V-Forge, Grill, four à pizza, panier à grillade, panier multi-usage, portatif et fabriqué en acier inoxydable de grade alimentaire." : "V-Forge, Grill, Pizza Oven, Grill Basket, Multi-Use Basket, Portable and Made of Food Grade Stainless Steel."} />
              <meta name="DC.Subject" content={ actLang === 'fr' ? "grill, barbecue, bbq, plein air, grill portatif, bbq portatif, grillage, accessoire grill, accessoire bbq, accessoire barbecue, four pizza, four à pizza, four pizza portatif" : "grill, barbecue, bbq, outdoors, portable grill, portable bbq, grilling, grill accessory, bbq accessory, barbecue accessory, pizza oven, pizza oven, portable pizza oven"} />
              <meta name="DC.format" content="text/html" />
              <meta name="DC.language" scheme="RFC3066" content={ actLang === 'fr' ?  "fr-ca" : "en-ca"} />

              <meta name="Author" content="Les Fabrications TJD inc." />
              <meta name="Copyright" content="Les Fabrications TJD inc." />
              <meta name="Revisit-After" content="10 days" />
              <meta name="robots" content="all, noodp, noydir" />
              <meta name="Date-Revision-yyyymmdd" content="20220617" />
              <meta content={actLang} http-equiv="content-language" />
              <link href={'http://www.vforge.ca/' + actLang} rel="canonical" />

              <link href="https://www.vforge.ca/fr" title="V-Forge Grill et accessoires portatif en acier inxydable" rel="start" />
              <link href="https://www.vforge.ca/en" title="V-Forge Stainless steel portable grill and accessories" rel="next" />

        </Helmet>
        <Global />
        <Header lang={actLang} />
           
        {/*<img src={Pub} alt='VForge' width='90%' style={{backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '1pc', padding: '5pc', boxShadow: '5px 5px 5px 0px #808080', marginTop: '0.5pc', marginBottom: '0.5pc'}} ></img>*/}
        <Product lang={actLang} catId={33} /> {/*catId=33 => V-Forge*/}
        <Footer lang={actLang} />
      </div>
    );
  }
}

export default Home;
