import Home from "./pages/home"
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./styles/app.css";

function App() {

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };

  return (
    <>
    <div className="App">
      <BrowserRouter>
          <Routes>
            <Route path="/" index element={<Home />} />
            <Route path="/en" element={<Home lang={'en'}/>} />
            <Route path="/fr" element={<Home lang={'fr'} />} />
          </Routes>
      </BrowserRouter>
    </div>
    {showButton && (
      <button onClick={scrollToTop} className="back-to-top">
        &#8679;
      </button>
    )}
    </>
  );
}

export default App;
