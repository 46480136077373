import { useEffect, useState } from "react";
import pdfLogo from '../images/pdf-downlod-logo.png'

function Docs(props){
    const link = '/server/docs/' + props.prodNumber;
    
    useEffect( () => {
        fetchItems();
    }, []);
    
    const [docs, setItems] = useState([]);
    
    const fetchItems = async () => {
        const data = await fetch(link);
        const docs = await data.json();
        setItems(docs);
    };

    return (
        docs.map((doc) => {
            return <div key={doc.id} className="App-product-doc" >
                    <a href={doc.src} target="_blank" rel="noreferrer" style={{color: 'black', textDecoration: 'none'}}><img src={pdfLogo} style={{height:'8pc'}} alt={'vforge-' + doc.fileName + '-documentation'} /><br />
                    <div style={{textAlign:"center", marginTop: '-1pc', marginBottom: '1pc'}}>{doc.fileName}</div></a>
                </div>
        })
    );
}
export default Docs;