import React from "react";
//import i18n from '../i18n';
import sanitizeHtml from "sanitize-html";

export const jumpToReleventDiv = (id) => {
    const releventDiv = document.getElementById(id);
    // behavior: "smooth" parameter for smooth movement
    releventDiv.scrollIntoView({behavior: "smooth",  block: "start"});
    }

export const getText = (text) => {
        return sanitizeHtml(text)
      }

/*
export const changeLang = () => {
    i18n.changeLanguage(i18n.language === 'fr' ? 'en' : 'fr')
    window.location.href = "/" + i18n.language === "fr" ? "" : i18n.language;
  }

export const setLang = () => {
  if(window.location.pathname === "/en" ? i18n.changeLanguage("en") : i18n.changeLanguage("fr"));
}
*/