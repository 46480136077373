import React from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ReactComponent as Logo } from "../images/arrow_forward.svg"
import ReactPlayer from "react-player/youtube";
import InnerImageZoom from "react-inner-image-zoom";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'; //styles.min.css
import '../styles/imageZoom.css'

class ResponsiveCarouselComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          slideSelected: 0,
          mediaType: "pic"
        };
    }
/*
    onChangeEvent = () => {
        console.log('onChange Event Triggered');
    }

    onClickItemEvent = () => {
        console.log('onClickItem Event Triggered');
    }

    onClickThumbEvent = () => {
        console.log('onClickThumb Event Triggered');
    }

    onSwipeStartEvent = () => {
        console.log('onSwipeStart Event Triggered');
    }

    onSwipeEndEvent = () => {
        console.log('onSwipeEnd Event Triggered');
    }

    onSwipeMoveEvent = () => {
        console.log('onSwipeMove Event Triggered');
    }*/
    
    changeSlide = index => {
        this.setState({"slideSelected": index})
        this.setState({"mediaType": this.props.myList[index]["type"]})
        console.log("slideSelected: " + this.state.slideSelected, "mediaType: " + this.state.mediaType)
    }

    render() {
    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 45,
        height: 60,
        cursor: 'pointer',
        border: 'none',
        background: '#00000070', /*#1501ca*/
        borderRadius: '0.25pc'
    };

    const indicatorStyles = {
        background: '#1501ca',
        width: 10,
        height: 10,
        display: 'inline-block',
        marginLeft: '0.1pc',
        marginRight: '0.1pc',
        borderRadius: "50%"
    };

    //const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;

    // 'default' key (size: 120 x 90)
    // https://i.ytimg.com/vi/hEwpjRHRpC8/default.jpg

    // 'medium' key (size: 320 x 180)
    // https://i.ytimg.com/vi/hEwpjRHRpC8/mqdefault.jpg

    // 'high' key (size 480 x 360)
    // https://i.ytimg.com/vi/hEwpjRHRpC8/hqdefault.jpg

    // 'standard' key (size 480 x 360)
    // https://i.ytimg.com/vi/hEwpjRHRpC8/sddefault.jpg

    // 'maxres' key (size 1280 x 720)
    // https://i.ytimg.com/vi/hEwpjRHRpC8/maxresdefault.jpg

    const getVideoThumb = (videoId) => `https://i.ytimg.com/vi/${videoId}/sddefault.jpg`;

    //const getVideoId = (url) => url.substr('https://www.youtube.com/embed/'.length, url.length);

    const customRenderThumb = (children) =>
        children.map((item,i) => {
            if(item.props.children.props.url) {
                console.log("videos_" + i, item.props.children.props);
                //const videoId = getVideoId(item.props.children.props.url);
                //console.log("videos_" + i, videoId);
                const img = getVideoThumb(item.props.children.props.videoId);
                console.log("videos_" + i, img);
                return <img src={img} alt={item.props.children.props.alt} />;
            }
            else{
                //console.log("pics_" + i, item.props.children.props.src, item.props.children.props.alt)
                return <img src={item.props.children.props.src} alt={item.props.children.props.alt} />;
            }
        });
    
    const config = {
        height:"100%",
        objectFit:"cover",
        youtube: {
            playerVars: {
                disablekb: 1,
                modestbranding: 1,
                showinfo: 0,
                iv_load_policy: 3,
                origin: 'https://www.youtube.com',
                disablePictureInPicture: true,
                rel: 0,
            }
        },
        file: {
            forceVideo: true,
            attributes: {
              preload: 'metadata',
            },
          },
    }

    const YoutubeSlide = ({ index, url, videoId, alt }) => (
        <ReactPlayer 
            playing={this.state.slideSelected === index ? true : false}
            title={alt}
            width="100%" 
            height="100%" 
            controls 
            alignSelf='center' 
            style={{ position: "absolute", top: 0, left: 0 }}
            url={url} 
            config={config}
        />
    );

    const ImageSlide = ({ index, src, alt }) => (
        <InnerImageZoom src={src} name={alt} zoomType="click" moveType="pan" fullscreenOnMobile={true} />
    );

    return (
        <div >
            <div className="carousel-container"  >
                <Carousel renderThumbs={customRenderThumb} style={{alignSelf: "center"}}
        
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
                <button type="button"  onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15, transform: 'rotate(180deg)' }}>                 
                    <Logo fill="white"  />
                </button>
            )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
                <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }} >
                    <Logo transform="rotate(180deg)" fill="white" />
                </button>
            )
        }
        renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
                return (
                    <li
                        style={{ ...indicatorStyles, background: '#7a93da' }}
                        aria-label={`Selected: ${label} ${index + 1}`}
                        title={`Selected: ${label} ${index + 1}`}
                    />
                );
            }
            return (
                <li
                    style={indicatorStyles}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                    title={`${label} ${index + 1}`}
                    aria-label={`${label} ${index + 1}`}
                />
            );
        }}
        onChange={this.changeSlide} onClick={this.onClickItemEvent} infiniteLoop={false} emulateTouch={false}  useKeyboardArrows={true} centerMode={true} showStatus={false} showThumbs={true} centerSlidePercentage={100} dynamicHeight={false} swipeable={false} showArrows={true} showIndicators={false} >

                    {
                        this.props.myList.map((media, i) => {
                            return <div key={media.id} className="App-product-carousel-element">
                                        {
                                        media.type === "pic" ? 
                                            <ImageSlide src={media.src} alt={media.alt} />
                                            :
                                            <YoutubeSlide key={media.alt} index={i} url={media.url} videoId={media.videoId} alt={media.alt} />
                                        }
                                    </div>
                            }) 
                    } 
                    </Carousel>
                </div>
            </div>
        );
    }
}

/*
                        <YoutubeSlide key="youtube-1" url="https://www.youtube.com/embed/AVn-Yjr7kDc" />
                        <YoutubeSlide key="youtube-2" url="https://www.youtube.com/embed/mOdmi9SVeWY" />
*/
export default ResponsiveCarouselComponent;