import { useEffect, useState } from "react";
import "../styles/carouselTabs.css";
import CarouselPictures  from "./carouselPictures";
import ResponsiveCarouselComponent from "./carouselComponents"

function CarouselTabs(props) {
    const link = '/server/videos/' + props.prodNumber + "/" + props.prodAlt;
    
    useEffect( () => {
        fetchVideos();
		fetchPics();
    }, []);
    
    const [videos, setVideos] = useState([]);
    
    
    const fetchVideos = async () => {
        const data = await fetch(link);
        const videos = await data.json();
        /*const videos = rsJson.map(video => "https://youtu.be/"+video)*/
        console.log(videos);
        setVideos(videos);
    };

	const picsLink = '/server/pics/' + props.prodId + "/" + props.prodAlt;
    
    const [pics, setPics] = useState([]);
    
    const fetchPics = async () => {
        const data = await fetch(picsLink);
        const pics = await data.json();
        //console.log("pics: " + JSON.stringify(pics));
        setPics(pics);
    };
    
  return (
        <div className="tab_container"  >
			{/*<CarouselPictures pics={pics} prodId={props.prodId} prodNumber={props.prodNumber} prodAlt={props.prodAlt} />*/}
            {/*<CarouselPictures pics={[...pics, ...videos]} prodId={props.prodId} prodNumber={props.prodNumber} prodAlt={props.prodAlt} />*/}
            <ResponsiveCarouselComponent myList={[...pics, ...videos]} />
		</div>
  );
}

export default CarouselTabs;