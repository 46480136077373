import React, { useEffect, useState } from 'react';
import {encode as base64_encode} from 'base-64';
import CarouselTabs  from "./carouselTabs";
import '../styles/app.css';
import TJD from '../images/TJD-transparence.png';
import VForge from '../images/vforge-logo_web_qc_patent_small.png';
import Docs from './docs';
import { getText } from "./functions";
const backgroundWall = require("../images/wallpaperflare.com_wallpaper.jpg");

const divStyle = {
  backgroundImage: `url(${backgroundWall})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'fixed',
  width: '95%',
  borderRadius: '1pc',
  boxShadow: '5px 5px 5px 0px #808080',
  marginTop: '0.5pc',
  marginBottom: '0.5pc',
  flexDirection: 'column'
};

function Product(props){
    const linkProd = '/server/getprods/' + props.catId;

    const [items, setItems] = useState([]);

    const fetchItems = async () => {
        const data = await fetch(linkProd);
        const items = await data.json();
        setItems(items);
    };

    useEffect( () => {
        fetchItems();
    }, []);

    const [promoCode, setPromoCode] = useState('');

    return(
        items.map((prod) => {
            return(
            <div key={prod.noproduit} style={divStyle}>
                <div className="App-product-header" style={{borderRadius: '1pc 1pc 0pc 0pc'}} >
                    <p style={{alignSelf: "center"}}>
                        {prod.noproduit} - {props.lang === 'fr' ? prod.nomfr : prod.nomen} 
                    </p>
                </div>   
                <div key={prod.id} style={{display: 'flex', flexDirection: 'row', flexWrap: "wrap", borderRadius: "0pc 0pc 1pc 1pc"}} >
                    <div className="App-product-carousel" width='100%'>
                        <CarouselTabs lang={props.lang} prodId={prod.id} prodNumber={prod.noproduit} prodAlt={'VForge_' + prod.noproduit + "_" + (props.lang === 'fr' ? prod.nomfr : prod.nomen)} />
                        <img src={VForge} alt='VForge-logo' className='App-header-logo' style={{height: '30vh', margin: '1pc', objectFit:"scale-down"}} ></img>
                    </div>
                    <div className="App-product" style={{borderRadius: "0pc 0pc 1pc"}} >
                        <div className="App-product-desc" >
                            <div dangerouslySetInnerHTML={{ __html: props.lang === 'fr' ? (prod.descfr == null ? "" : getText(prod.descfr.replace(/\n/g,"<br />"))) : (prod.descen == null ? "" : getText(prod.descen.replace(/\n/g,"<br />")))}} />
                        </div>
                        <br />
                        <br />
                        <div className="App-product-desc">
                            <Docs prodNumber={prod.noproduit}/>
                        </div>

                        <div className="App-order" >
                            <div className="App-order-column" >
                                <text className="App-order-price" >{props.lang === 'fr' ? 'Prix: ' + prod.prix + '$ CAD'  : 'Price: ' + prod.prix + '$ CAD'}</text>
                                <input name='promo' type='text' value={promoCode} onInput={e => setPromoCode(e.target.value)} className='App-order-promo' placeholder={props.lang === 'fr' ? 'Code promo'  : 'Promo code'} style={{maxWidth: '10pc', alignSelf:'center', borderRadius:'0.25pc', border:'0.1pc'}}/>
                            </div>
                            <div className="App-order-column" >
                                <button  className="App-order-btn App-order-btn2" onClick={() => {window.open("https://www.tjd.ca/shoppingtjd/panier.php?a=" + base64_encode(prod.id) + "&n=" + base64_encode(1) + "&t=" + base64_encode(prod.typep) + "&cpr=" + promoCode + "&lang=" + props.lang); setPromoCode('');}} style={{alignSelf: 'center'}}>
                                    <img src={TJD} alt="tjd-logo" className="App-order-btn-logo" ></img><span >{props.lang === 'fr' ? "Commander" : "Order now"}</span>
                                </button>
                                <div className="App-order-notice" style={{alignSelf: 'center', marginLeft: '0.5pc'}}> {props.lang === 'fr' ? 'Transaction effectuée via notre site sécurisé shoppingtjd.ca'  : 'Transaction made through our secure site shoppingtjd.ca'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
        )
    )
}

export default Product;

        