import React from "react";
import TJD from '../images/TJD-transparence.png';

class footer extends React.Component {
    render() {
        return(
            <div className='App-footer-background' style={{alignItems: 'center'}}>
                    <a href="https://www.shoppingtjd.ca" target="_blank" rel="noreferrer"><img src={TJD} alt='TJD-logo' className='App-footer-logo' ></img></a>
                    <text style={{fontWeight: "bold", color:"#012179", marginTop: '-0.75pc'}} >
                        © 2022 {this.props.lang === 'fr' ? "Tous Droits Réservés" : "All right reserved"}
                    </text>
            </div>
        );
    }
}

export default footer;