import React from "react";
import VForge from '../images/vforge-logo.png';
import { Link } from 'react-router-dom'

class header extends React.Component {
    render() {
        return(
            <div className='App-header' >
                <div className='App-header-column' >
                    <img src={VForge} alt='VForge-logo' className='App-header-logo' style={{padding: '0.3pc'}} ></img>
                </div>
                <div className='App-header-column' style={{flexGrow: 1, flexShrink: 1, alignSelf: 'center'}}>
                    {/*<text>{this.props.lang === 'fr' ? "MAINTENANT DISPONIBLE!" : "AVAILABLE NOW!"}</text> */}
                </div>
                <div className='App-header-column' style={{alignSelf: 'center', textAlign: 'center'}}>
                    <Link className='App-lang-btn App-lang-btn2' to={this.props.lang === 'fr' ? "/en" : "/fr"} >{this.props.lang === 'fr' ? "ENGLISH" : "FRANÇAIS"}</Link>
                </div>
            </div>
        );
    }
}

export default header;
        